<template>
  <div>
    <a-breadcrumb class="breadcrumb">
      <a-breadcrumb-item>首页</a-breadcrumb-item>
      <a-breadcrumb-item>市场应用</a-breadcrumb-item>
      <a-breadcrumb-item>活动管理</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="main-body students">
      <a-tabs :animated="false" :activeKey="activeKey" @change="changeTab">
        <a-tab-pane key="1">
          <span slot="tab">活动管理<a-badge :count="0" /></span>
          <hdgl v-if="activeKey==='1'"/>
        </a-tab-pane>
        <!-- <a-tab-pane key="2">
          <span slot="tab">活动签到<a-badge :count="0" /></span>
          <hdqd v-if="activeKey==='2'"/>
        </a-tab-pane> -->
        <!-- <a-tab-pane key="3">
          <span slot="tab">签到扫描设置<a-badge :count="0" /></span>
          <qdsmsz v-if="activeKey==='3'"/>
        </a-tab-pane> -->
        <!-- <a-tab-pane key="4">
          <span slot="tab">优惠票渠道<a-badge :count="0" /></span>
          <yhqqd v-if="activeKey==='4'"/>
        </a-tab-pane> -->
        <!-- <a-tab-pane key="5">
          <span slot="tab">活动媒体报名<a-badge :count="0" /></span>
          <hdmtbm v-if="activeKey==='5'"/>
        </a-tab-pane> -->
        <!-- <a-tab-pane key="6">
          <span slot="tab">活动代理商<a-badge :count="0" /></span>
          <hddls v-if="activeKey==='6'"/>
        </a-tab-pane> -->
        <!-- <a-tab-pane key="7">
          <span slot="tab">活动报名办理<a-badge :count="0" /></span>
          <hdbmbl v-if="activeKey==='7'"/>
        </a-tab-pane> -->
      </a-tabs>
    </div>
  </div>
</template>

<script>
import hdgl from './hdgl/index'
import hdqd from './hdqd/index'
import qdsmsz from './qdsmsz/index'
import yhqqd from './yhqqd/index'
import hdmtbm from './hdmtbm/index'
import hddls from './hddls/index'
import hdbmbl from './hdbmbl/index'

export default {
  components: {
    hdgl,
    hdqd,
    qdsmsz,
    yhqqd,
    hdmtbm,
    hddls,
    hdbmbl
  },
  data() {
    return {
      activeKey: '1'
    }
  },

  methods: {
    changeTab(key) {
      this.activeKey = key
    }
  }
}
</script>

