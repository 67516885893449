<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="确定" width="65.5%" :footer="null"
    @ok="handleOk" @cancel="handleCancel">
    <a-card title="" size="small" style="margin-bottom: 10px;">
      <a-row :gutter="24">
        <vue-element-loading :active="cardLoading" color="#00cca2" spinner="spinner"/>
        <a-col :span="12">
          <span class="t">活动名称：</span><span class="v">{{ detail.meeting_name }}</span>
        </a-col>
        <!-- <a-col :span="12">
          <span class="t">活动状态：</span><span class="v">{{ item.status }}</span>
        </a-col> -->
        <a-col :span="12">
          <span class="t">合计收入：</span><span class="v">{{ item.meeting_recevie_total }}元</span>
        </a-col>
        <a-col :span="12">
          <span class="t">添加时间：</span><span class="v">{{ item.created_at }}</span>
        </a-col>
        <a-col :span="12">
          <span class="t">报名人次：</span><span class="v">{{ detail.already_count }}人</span>
        </a-col>
        <a-col :span="12">
          <span class="t">开始时间：</span><span class="v">{{ detail.open_time }}</span>
        </a-col>
        <a-col :span="12">
          <span class="t">退款人次：</span><span class="v">0</span>
        </a-col>
        <a-col :span="12">
          <span class="t">结束时间：</span><span class="v">{{ detail.close_time }}</span>
        </a-col>
        <a-col :span="12">
          <span class="t">报名人数：</span><span class="v">{{ detail.already_count }} / {{ detail.ticket_count }}人</span>
        </a-col>
      </a-row>
    </a-card>
    <a-card title="报名列表" size="small">
      <div class="clearfix table-tools">
        <div class="buttons">
          <a-form layout='inline'>
            <a-form-item>
              <a-button icon="export" @click="toExport" :loading="exportLoading">导出</a-button>
            </a-form-item>
          </a-form>
        </div>
        <div class="search">
          <a-form layout='inline'>
            <a-form-item>
                <a-tooltip placement="topLeft" >
                <template slot="title">
                    <span>真实姓名</span>
                </template>
                <a-input v-model.trim="searchParams.search.name" placeholder="请输入姓名" style="width: 140px"/>
                </a-tooltip>
            </a-form-item>
            <a-form-item>
                <a-tooltip placement="topLeft" >
                <template slot="title">
                    <span>手机号</span>
                </template>
                <a-input v-model.trim="searchParams.search.cellphone" placeholder="请输入手机号" style="width: 140px"/>
                </a-tooltip>
            </a-form-item>
            <a-form-item>
                <a-tooltip placement="topLeft" >
                <template slot="title">
                    <span>票务类型</span>
                </template>
                <a-select allowClear show-search @search="handleSearch" :filterOption="filterOption" v-model="searchParams.search.type_id" placeholder="请选择票务类型" style="width: 140px">
                  <a-select-option v-for="(d, index) of ticketType" :key="index" :value="d['type_id']">{{ d['filter_name'] }}</a-select-option>
                </a-select>
                </a-tooltip>
            </a-form-item>
            <a-form-item>
                <a-button @click="searchList" type="primary">筛选</a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
      <div class="table">
        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
        <a-table size="small" :pagination="false" :bordered='false' rowKey="ticekt_id"
          :columns="columns" :dataSource="list" @change="handleChange" :scroll="{ x: 800 }">
            
            <template slot="check_status" slot-scope="text,record">
              <a-switch checkedChildren="已签到" unCheckedChildren="未签到" :checked="record.check_status == '1'" @change="changeStatus(record)"/>
            </template>
        </a-table>
      </div>
      <div class="page">
        <a-pagination
          :pageSizeOptions="pageSizeOptions"
          :total="pageParams.totalCount"
          showSizeChanger
          :pageSize="pageParams.perPage"
          v-model="current"
          @change="changePage"
          @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
        >
            <template slot='buildOptionText' slot-scope='props'>
              <span v-if="props.value!=='100'">{{props.value}}条/页</span>
              <span v-if="props.value==='100'">100条/页</span>
            </template>
          </a-pagination>
      </div>
    </a-card>
    <profileModal v-if="profileVisible" :studentId="studentId"/>
  </a-modal>
</template>

<script>
import moment from 'moment'
import profileModal from '@/views/modal/profile'
import tableMixins from '@/common/mixins/table'
export default {
  name: 'AddModal',
  inject: ['parent'],
  mixins: [ tableMixins ],
  props: {
    item: Object
  },

  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 15 },
      ModalTitle: '活动详情',
      visible: false,
      loading:false,
      cardLoading:false,
      confirmLoading: false,
      exportLoading: false,
      form: this.$form.createForm(this),
      detail: {},
      columns:[],
      list: [],
      profileVisible: false,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      ticketType:[]
    }
  },

  async created() {
    this.visible = true
    this.loading = true
    this.getTicketType({meeting_id:this.item.meeting_id})
    await this.getDetail()
    await this.getList()
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    toProfile(item) {
      this.studentId = item.student_id
      this.showProfileModal()
    },

    showProfileModal() {
      this.profileVisible = true
    },
    hideProfileModal() {
      this.profileVisible = false
    },
    async getDetail() {
      this.cardLoading = true
      let { meeting_id } = this.item
      let res = await this.$store.dispatch('meetingCampaignDetailAction', {data: {meeting_id}})
      this.detail = res.data
      let meeting_form = []
      let arr = [
        { title: '#', width:'55px', customRender:(text,record,index)=>`${index+1}`, key: 'index'},
        { title: '订单号', width:'140px',  dataIndex: 'out_trade_no', key: 'out_trade_no'}
      ]
      let columns = [
        { title: '票务类型', dataIndex: 'type_id', key: '31' },
        { title: '价格', dataIndex: 'ticket_price', key: '3' },
        { title: '购买票数', dataIndex: 'ticket_count', key: '4',align:"center" ,sorter:()=>{}},
        { title: '报名时间', dataIndex: 'created_at', key: '5' ,sorter:()=>{}},
        { title: '报名状态', dataIndex: 'status', key: '6' ,sorter:()=>{}},
        { title: '来源', dataIndex: 'source_channel_id', key: 'source_channel_id' ,sorter:()=>{}},
        { title: '签到状态', dataIndex: 'check_status', key: '7' , scopedSlots: { customRender: 'check_status' },sorter:()=>{}},
      ]
      res.data.meeting_form.forEach(item=>{
        arr.push({ title: item.filedName, dataIndex: item.filedDataName, key: item.filedDataName})
      })
      this.columns = [...arr,...columns]
      this.cardLoading = false
    },
    async getList() {
      let { meeting_id } = this.item
      this.loading = true
      this.searchParams.meeting_id = meeting_id
      let res = await this.$store.dispatch('meetingTicketsAction', {data: this.searchParams})
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },
    async getTicketType(obj){
      let res = await this.$store.dispatch('searchCampaignTicketTypeAction', {params: obj})
      this.ticketType = res.data
    },
    handleChange(pagination, filters, sorter){
        if(sorter.order){
            if(sorter.order === "ascend"){
                this.searchParams['sort'] = `${sorter.field}`
            }else{
                this.searchParams['sort'] = `-${sorter.field}`
            }
            }else{
            this.searchParams['sort'] = ''
            }
        this.getList()
    },
    handleOk() {
      this.parent.hideListModal(1)
    },
    handleCancel() {
      this.parent.hideListModal(0)
    },

    changeStatus(record) {
      if (record.check_status == '1') {
        this.deleteItem(record)
      } else {
        this.recoveryItem(record)
      }
    },

    deleteItem(item) { 
      let that = this
      this.$confirm({
        title: `您确定要取消"${item.student_name}"吗？`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('meetingTicketsUnCheckinAction', { data: { ticket_id: [item.ticekt_id] } })
            if (res) {
              that.getTickets()
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    },
    recoveryItem(item) {
      let that = this
      this.$confirm({
        title: `您确定要签到"${item.student_name}"吗？`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('meetingTicketsCheckinAction', { data: { ticket_id: [item.ticekt_id] } })
            if (res) {
              that.getTickets()
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    },
    handleSearch(e){
      let obj = {q:e,meeting_id:this.item.meeting_id}
      this.getTicketType(obj)
    },
    async toExport(){
      this.exportLoading = true
      let res = await this.$store.dispatch('meetingCampaignDownloadExportListAction', {meeting_id:this.item.meeting_id})
      const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
      if ('download' in document.createElement('a')) { 
          const link = document.createElement('a')
          
          link.download = `${this.detail.meeting_name}.${moment().format('YYYY.MM.DD')}.xlsx`
          link.style.display = 'none'
          link.href = URL.createObjectURL(blob)
          document.body.appendChild(link)
          link.click()
          URL.revokeObjectURL(link.href) 
          document.body.removeChild(link)
      } else { //其他浏览器
          navigator.msSaveBlob(blob, fileName)
      }
      this.exportLoading = false
    }
  }
}
</script>