<template>
  <a-modal title="生成二维码" v-model="visible" cancelText="取消" okText="下载" width="650px"
    @ok="handleOk" @cancel="handleCancel" class="survey-qrcode">
        <div :style="{height:wHeight}" class="hdgl-qrcodess">
            <vue-canvas-poster :widthPixels="750" :painting="painting" @success="success" @fail="fail"></vue-canvas-poster>
        </div>
  </a-modal>
</template>

<script>
    var bussinessman =  require('../../../../assets/bussiness-man.png')
    var clock =  require('../../../../assets/clock.png')
    var CustomerManagement =  require('../../../../assets/Customer-management.png')
    var map =  require('../../../../assets/map.png')
    import { VueCanvasPoster } from 'vue-canvas-poster'
    export default {
        name:'qrcodeModal',
        inject: ['parent'],
        data() {
            return {
                visible: false,
                painting: {
                    width: '375px',
                    height: '667px',
                    background: '#f4f5f7',
                    views: [
                        {
                            type: 'image',
                            url: this.item.meeting_cover,
                            css:{
                                width: '375px',
                                height: '200px',
                                mode:'scaleToFill',
                            }
                        },
                        {
                            type: 'rect',
                            css: {
                                color: '#fff',
                                width: '375px',
                                height: '467px',
                                top:'200px',
                                left:'0px',
                            }
                        },
                        {
                            type: 'text',
                            text: this.item.meeting_name,
                            css:[
                                {
                                    width: '360px',
                                    top:'230px',
                                    left:'13px',
                                    fontSize:'14.5px',
                                    lineHeight:'20px',
                                }
                            ]
                        },
                        {
                            type: 'image',
                            url: clock,
                            css:{
                                width: '14px',
                                height: '14px',
                                top:'300px',
                                left:'15px',
                                mode:'scaleToFill',
                            }
                        },
                        {
                            type: 'text',
                            text: this.item.meeting_date.replace('<br/>','~'),
                            css:[
                                {
                                    width: '280px',
                                    top:'300px',
                                    left:'40px',
                                    fontSize:'10px',
                                    color:'#555'
                                }
                            ]
                        },
                        {
                            type: 'image',
                            url: bussinessman,
                            css:{
                                width: '14px',
                                height: '14px',
                                top:'330px',
                                left:'15px',
                                mode:'scaleToFill',
                            }
                        },
                        {
                            type: 'text',
                            text: `报名数：${this.item.ticket_count?this.item.ticket_count:'不限'}`,
                            css:[
                                {
                                    width: '280px',
                                    top:'360px',
                                    left:'40px',
                                    fontSize:'10px',
                                    color:'#555'
                                }
                            ]
                        },
                        {
                            type: 'image',
                            url: map,
                            css:{
                                width: '14px',
                                height: '14px',
                                top:'390px',
                                left:'15px',
                                mode:'scaleToFill',
                            }
                        },
                        {
                            type: 'text',
                            text: `主办方：${this.item.meeting_organizer}`,
                            css:[
                                {
                                    width: '280px',
                                    top:'330px',
                                    left:'40px',
                                    fontSize:'10px',
                                    color:'#555'
                                }
                            ]
                        },
                        {
                            type: 'image',
                            url: CustomerManagement,
                            css:{
                                width: '14px',
                                height: '14px',
                                top:'360px',
                                left:'15px',
                                mode:'scaleToFill',
                            }
                        },
                        {
                            type: 'text',
                            text: this.item.address,
                            css:[
                                {
                                    width: '280px',
                                    top:'390px',
                                    left:'40px',
                                    fontSize:'10px',
                                    color:'#555'
                                }
                            ]
                        },


                        {
                            type: 'qrcode',
                            content: this.item.access_url,
                            css: {
                                width:'80px',
                                height:'80px',
                                bottom:'20px',
                                right:'15px',
                            }
                        },

                    ],
                },
                active:1,
                imgurl:'',
                wHeight:'667px'
            }
        },
        props:{
            item:Object,
        },
        components: {
            VueCanvasPoster,
        },
        created(){
            this.visible = true
        },
        methods: {
            handleOk() {
                this.downloadFile(`${this.item.meeting_name}.png`,this.imgurl)
            },
            handleCancel(){this.parent.hideQrCodeModal()},
            success(src) {
                this.imgurl = src
            },
            fail(err) {
                console.log('fail', err)
            },
            downloadFile(fileName, content) {
                let aLink = document.createElement('a');
                let blob = this.base64ToBlob(content); //new Blob([content]);
                let evt = document.createEvent("HTMLEvents");
                evt.initEvent("click", true, true);//initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
                aLink.download = fileName;
                aLink.href = URL.createObjectURL(blob);
                aLink.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));//兼容火狐
            },
            base64ToBlob(code) {
                let parts = code.split(';base64,');
                let contentType = parts[0].split(':')[1];
                let raw = window.atob(parts[1]);
                let rawLength = raw.length;

                let uInt8Array = new Uint8Array(rawLength);

                for (let i = 0; i < rawLength; ++i) {
                uInt8Array[i] = raw.charCodeAt(i);
                }
                return new Blob([uInt8Array], {type: contentType});
            },
        }
    }
</script>

<style lang="scss">
    .survey-qrcode{
        .ant-modal-body{
            max-height: 2000px;
            background: #f4f5f7;
        }
    }
    .hdgl-qrcodess{
        text-align: center;
        div{
            width: 240px;
        }
        .canvas{
            position: absolute  !important;
            top:inherit  !important;
            width: 375px !important;
            height: 667px !important;
        }
    }
</style>