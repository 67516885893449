<template>
  <a-modal title="活动海报" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="423px"
    @ok="handleOk" @cancel="handleCancel">
        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
        <div style="margin-bottom:10px">
            <a-upload
                accept='.png,.jpeg,.jpg'
                :data="uploadParams"
                :action="uploadUrl"
                :beforeUpload="beforeImageUpload"
                :showUploadList="false"
                listType="picture"
                @change="changeFile">
                <a-button :loading="upLoadLoading"> <a-icon type="upload" /> 选择图片 </a-button>
            </a-upload>
            <span>（尺寸：750x1334）</span>
        </div>
        <div class="posterModal-box" :style="{backgroundImage:fileList.length>0?`url(http://i01.wedots.cn/${fileList[0].response.key})`:''}">
            <div>
                <vue-drag-resize ref="vueDragResize" :isActive="true" :parentLimitation="true" :parentW="375" :parentH="667">
                    <img style="width:100%;height:100%" src="http://i01.wedots.cn/2021/04/14/863a0caef2ebad46472b221cc4ecf061.png?imageView2/5/w/400/h/400/format/png/q/75">
                </vue-drag-resize>
            </div>
        </div>
  </a-modal>
</template>

<script>
    import url from '@/utils/URL'
    import createFilePath from '@/utils/tools'
    import VueDragResize from 'vue-drag-resize'
    export default {
        name:'posterModal',
        inject: ['parent'],
        data() {
            return {
                visible: false,
                loading: false,
                confirmLoading:false,
                upLoadLoading:false,
                fileList:[],
                uploadParams: {},
                uploadUrl: url.uploadQiNiu,
            }
        },
        components: {
            VueDragResize,
        },
        props: {
            item:Object
        },
        created () {
            this.visible = true
            this.getDetail()
        },
        methods: {
            async getDetail(){
                this.loading = true
                await this.$store.dispatch('toolsSharePosterDetailAction',{data:{share_type:'meeting',share_type_id:this.item.meeting_id}})
                .then(res=>{
                    if(res.data){
                        this.fileList = [{response:{key:res.data.share_image}}]
                        this.$nextTick(()=>{
                            this.$refs.vueDragResize.rect.height = res.data.share_params.height
                            this.$refs.vueDragResize.rect.left = res.data.share_params.left
                            this.$refs.vueDragResize.rect.top = res.data.share_params.top
                            this.$refs.vueDragResize.rect.width = res.data.share_params.width
                            this.$refs.vueDragResize.left = res.data.share_params.left
                            this.$refs.vueDragResize.top = res.data.share_params.top
                            this.$refs.vueDragResize.right = res.data.share_params.right
                            this.$refs.vueDragResize.bottom = res.data.share_params.bottom
                            this.loading = false
                        })
                    }else{
                        this.loading = false
                    }
                })
                .catch(err=>{
                    this.loading = false
                })
            },
            async handleOk() {
                if(this.fileList.length === 0){
                    this.$message.warning("请上传海报背景!!")
                    return false
                }
                let rect = this.$refs.vueDragResize.rect
                rect.right = this.$refs.vueDragResize.right
                rect.bottom = this.$refs.vueDragResize.bottom
                let obj = {
                    share_type:'meeting',
                    share_type_id:this.item.meeting_id,
                    share_image:this.fileList[0].response.key,
                    share_params:JSON.stringify(rect)
                }
                await this.$store.dispatch('toolsSharePosterAddAction',{data:obj})
                .then(res=>{
                    this.$message.success('操作成功~')
                })
                .catch(err=>{
                    this.$message.error('操作失败~')
                })
            },
            handleCancel(){
                this.parent.hidePosterModal()
            },
            async beforeImageUpload(file) {
                if (!file) { return false }
                let res = await this.$store.dispatch('imageTokenAction', {})
                if (res) {
                    this.uploadParams.token = res.data.uptoken
                    this.uploadParams.key = createFilePath(file.name)
                    return true
                }
                return false
            },
            changeFile({ file }) {
                this.upLoadLoading = true
                if(file.status == "done"){
                    this.fileList = [file]
                    this.upLoadLoading = false
                }
            },
        },
    }
</script>

<style lang="scss">
    .posterModal-box{
        width: 375px;
        height: 667px;
        background: #ccc;
        position: relative;
        background-size: 375px 667px;
        background-repeat: no-repeat;
    }
</style>