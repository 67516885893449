<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="52.5%"
    @ok="handleOk" @cancel="handleCancel">
    <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
    <a-form :form="form">
      <a-form-item v-for="(item, index) of formData" :key="index" 
        :label="item.label" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>
        <a-cascader
          v-if="item.type === 'distpicker'"
          :options="chinaDivision"
          placeholder="请选择"
          v-decorator="[item.name, { rules: item.rules }]" 
          @change="onChangeOptions"/>
        <a-tree-select v-else-if="item.type === 'tags'"
            v-decorator="[item.name, { rules: item.rules }]"
            :tree-data="tags"
            tree-checkable
            allowClear
            treeNodeFilterProp="title"
            :maxTagCount='1'
            :maxTagTextLength='5'
            :dropdownStyle="{zIndex:4200,maxHeight:'400px'}"
            :show-checked-strategy="SHOW_ALL"
            placeholder="请选择标签"
        />
        <LMap v-if="item.type === 'map' && isEdit === 0" v-decorator="[item.name, { rules: item.rules }]"  :valueData="mapValue"/>
        <LMap v-if="item.type === 'map' && isEdit !== 0 && showMap" v-decorator="[item.name, { rules: item.rules }]"  :valueData="mapValue"/>
        <div v-decorator="[item.name, { rules: item.rules }]" v-if="item.type === 'upload'" style="display: inline-block; position: relative;" @click="showImagesModal(item.name)">
          <div v-if="imageUrl">
            <LImg :src="imageUrl" style="width: 100px; height: 100px;"/>
            <div class="close" @click="closeImg($event)">&times;</div>
          </div>
          <a-button v-else type="dashed" icon="file-image">选择图片</a-button>
        </div>
        <div v-decorator="[item.name, { rules: item.rules }]" v-if="item.type === 'upload2'" style="display: inline-block; position: relative;">
          <a-button type="dashed" icon="file-image" @click="showImagesModal(item.name)">选择图片</a-button>
          <div style="display: flex; flex-wrap: wrap;">
            <div style="position: relative; margin: 0 10px 10px 0;" v-for="(img, index) of imageUrls" :key="index">
              <LImg :src="img" style="width: 100px; height: 100px;"/>
              <div class="close" @click="closeImg2($event, index)">&times;</div>
            </div>
          </div>
        </div> 
        <a-radio-group v-if="item.type === 'radio'" placeholder="请选择" v-decorator="[item.name, { rules: item.rules }]">
          <a-radio v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-radio>
        </a-radio-group >
        <a-date-picker v-if="item.type === 'date'" style="width: 100%"  v-decorator="[item.name, { rules: item.rules }]" :show-time="{ format: 'HH:mm' }" format="YYYY-MM-DD HH:mm"/>
        <a-select allowClear show-search :filter-option="filterOption" v-else-if="item.type === 'select'" placeholder="请选择"
           v-decorator="[item.name, { rules: item.rules }]">
          <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
        </a-select>
        <l-editor v-decorator="[item.name, { rules: item.rules }]" v-else-if="item.type === 'textarea'" name="meeting_description" :data="meeting_description"/>
        <div class="table" v-else-if="item.type === 'ticket'">
            <a-table size="small" :pagination="false" :bordered='false' rowKey='id'
              :columns="columns" :dataSource="list">
                <template slot="name" slot-scope="text,record">
                  <a-input :value="text" :disabled="record.type_status === 0" @change="e=>handleChange(e,record,'name',0)" placeholder="票名称" />
                </template>
                <template slot="count" slot-scope="text,record,index">
                  <a-input-number :disabled="record.type_status === 0" :id="'max_count' + index" @change="e=>handleChange(e,record,'max_count',1)" :value="text" :min="0" placeholder="0(不限)"/>
                </template>
                <template slot="price" slot-scope="text,record">
                  <a-input-number :disabled="record.type_status === 0" :id="'price' + index" @change="e=>handleChange(e,record,'price',1)" :value="text" :min="0" placeholder="0(免费)"/>
                </template>
                <template slot="vip" slot-scope="text,record">
                  <a-checkbox :disabled="record.type_status === 0" @change="e=>handleChange(e,record,'need_vip',2)" :checked='text == 1'>限学员</a-checkbox>
                </template>
                <template slot="refund" slot-scope="text,record">
                  <a-checkbox :disabled="record.type_status === 0" @change="e=>handleChange(e,record,'can_refund',2)" :checked='text == 1'>可退款</a-checkbox>
                </template>
                <template slot="purchase" slot-scope="text,record">
                  <a-checkbox :disabled="record.type_status === 0" @change="e=>handleChange(e,record,'can_repeat_purchase',2)" :checked='text == 1'>可购买</a-checkbox>
                </template>
                <template slot="action" slot-scope="text,record,index">
                  <a-popconfirm
                    title="您确认删除该票种?"
                    ok-text="确认"
                    cancel-text="取消"
                    v-if="list.length > 1"
                    v-show="record.type_status != 0"
                    @confirm="delTicket(index)"
                  >
                    <a href="#">删除</a>
                  </a-popconfirm>
                </template>
            </a-table>
            <div @click="addTicket" class="add-ticket">添加票种</div>
        </div>
      </a-form-item>
      <a-form-item label="报名表信息" :label-col="labelCol" :wrapper-col="wrapperCol">
        <div class="hdgl-sign">
          <div class="hdgl-sign-edit">
            <a-button @click="handleTagEdit" v-if="!isEditTag">编辑</a-button>
            <a-button @click="handleTagSave" v-if="isEditTag">保存编辑</a-button>
            <a-button @click="handleTagCancal" v-if="isEditTag">取消编辑</a-button>
          </div>
          <div v-if="!isEditTag">
            <div class="hdgl-sign-item" v-for="(item,index) in defaultList" :key="index">
              <a-checkbox disabled :checked="item.filedRequired">必填</a-checkbox>
              <span style="width:80px">{{item.filedName}}</span>
              <div v-if="item.filedValType === 'text'" class="hdgl-sign-input">{{item.filedPlaceholder}}</div>
              <a-radio-group v-if="item.filedValType === 'radio'">
                <a-radio>男</a-radio>
                <a-radio>女</a-radio>
                <a-radio>保密</a-radio>
              </a-radio-group>
            </div>
          </div>
          <div v-if="isEditTag">
            <div class="hdgl-sign-item" v-for="(item,index) in defaultListF" :key="index">
              <a-checkbox :disabled="index < 2" @change="e=>onChangeCheckbox(e,item)" :checked="item.filedRequired">必填</a-checkbox>
              <span style="width:80px">{{item.filedName}}</span>
              <a-input v-if="item.filedValType === 'text'" v-model="item.filedPlaceholder" style="width:72%;margin-right:10px" />
              <a-radio-group v-if="item.filedValType === 'radio'">
                <a-radio>男</a-radio>
                <a-radio>女</a-radio>
                <a-radio>保密</a-radio>
              </a-radio-group>
              <a-icon v-if='index > 1' style="fontSize:18px;cursor:pointer;" @click="deleteTag(index)" type="delete" />
            </div>
          </div>
          <div v-if="isEditTag" class="hdgl-sign-warp">
            <div @click="handleTagCheck(item)" :class="item.isCheck?'hdgl-sign-tag-check':'hdgl-sign-tag'" v-for='(item,index) in defaultAddDomSettingData' :key='index'>{{item.filedName}}</div>
          </div>
        </div>
      </a-form-item>
    </a-form>
    <ImageModal v-if="imagesVisible"/>
  </a-modal>
</template>

<script>
const formData = [
  {
    name: 'meeting_cover', 
    label: '活动头图 (尺寸690x360)',
    type: 'upload',
    rules: [{ required: true, message: '请输入活动头图!' }]
  },
  {
    name: 'meeting_name',
    label: '活动名称',
    type: 'input',
    rules: [{ required: true, message: '请输入活动名称!' }]
  },
  {
    name: 'meeting_hotline',
    label: '联系电话',
    type: 'input',
    rules: [{ required: true, message: '请输入活动联系电话!' }]
  },
  // {
  //   name: 'province',
  //   label: '省/市/区',
  //   type: 'distpicker',
  //   rules: [{ required: true, message: '请输入省/市/区!' }]
  // },
  // {
  //   name: 'address',
  //   label: '活动地址',
  //   type: 'map',
  //   rules: [{ required: true, message: '请输入活动地址!' }]
  // },
  {
    name: 'address',
    label: '活动地址',
    type: 'input',
    rules: [{ required: true, message: '请输入活动地址!' }]
  },
  {
    name: 'open_time',
    label: '开始时间',
    type: 'date',
    rules: [{ required: true, message: '请输入开始时间!' }]
  },
  {
    name: 'close_time',
    label: '结束时间',
    type: 'date',
    rules: [{ required: true, message: '请输入结束时间!' }]
  },
  {
    name: 'meeting_qywx_id',
    label: '企业微信',
    type: 'select',
    rules: [{ required: false}],
    items: {
      data: 'qywx_ids',
      label: 'filter_name',
      value: 'employee_id'
    }
  },
  {
    name: 'tags',
    label: '活动标签',
    type: 'tags',
    rules:[],
  },
  {
    name: 'meeting_images',
    label: '活动图片',
    type: 'upload2',
    rules: [{ required: true, message: '请输入活动图片!' }]
  },
  {
    name: 'show_booked',
    label: '显示报名数',
    type: 'radio',
    rules: [],
    items:{
      data:'booked',
      label: 'label',
      value: 'value'
    }
  },
  {
    name: 'meeting_description',
    label: '活动介绍',
    type: 'textarea',
    rules: [{ required: true, message: '请输入活动介绍!' }]
  },
  // {
  //   name: 'recruit_description',
  //   label: '活动报名表信息',
  //   type: 'input',
  //   rules: [{ required: true, message: '请输入活动报名表信息!' }]
  // },
  {
    name: 'meeting_ticket',
    label: '票务种类',
    type: 'ticket',
    rules: []
  },
]

const columns = [
  { title: '票种名称', dataIndex: 'name', width:'120px', key: '1' , scopedSlots: { customRender: 'name' }},
  { title: '票总数', dataIndex: 'max_count', key: '2' , scopedSlots: { customRender: 'count' }},
  { title: '价格 (元)', dataIndex: 'price', key: '3' , scopedSlots: { customRender: 'price' }},
  { title: '权限学员', dataIndex: 'need_vip', key: '4' , scopedSlots: { customRender: 'vip' }},
  { title: '可否退款', dataIndex: 'can_refund', key: '5' , scopedSlots: { customRender: 'refund' }},
  { title: '重复购买', dataIndex: 'can_repeat_purchase', key: '6' , scopedSlots: { customRender: 'purchase' }},
  { title: '操作', key: 'operation', scopedSlots: { customRender: 'action' }},
]

var defaultAddDomSettingData = [
            {
                filedType: 'input',
                filedValType: 'text',
                filedName: '年龄',
                filedRequired: true,
                filedPlaceholder: '请输入年龄',
                filedDataName: 'age',
                isCheck:false,
            },
            {
                filedType: 'input',
                filedValType: 'text',
                filedName: '邮箱',
                filedRequired: true,
                filedPlaceholder: '请输入邮箱',
                filedDataName: 'email',
                isCheck:false,
            },
            {
                filedType: 'input',
                filedValType: 'text',
                filedName: '微信号',
                filedRequired: true,
                filedPlaceholder: '请输入微信号',
                filedDataName: 'wechat_number',
                isCheck:false,

            },
            {
                filedType: 'input',
                filedValType: 'text',
                filedName: '身高',
                filedRequired: true,
                filedPlaceholder: '请输入身高',
                filedDataName: 'height',
                isCheck:false,
            },
            {
                filedType: 'input',
                filedValType: 'text',
                filedName: '体重',
                filedRequired: true,
                filedPlaceholder: '请输入体重',
                filedDataName: 'weight',
                isCheck:false,
            },
            {
                filedType: 'input',
                filedValType: 'radio',
                filedName: '性别',
                filedRequired: true,
                filedPlaceholder: '请选择性别',
                filedDataName: 'gender',
                filedDataRadio:{'1':"男",'2':"女",'0':"保密"},
                isCheck:false,
            },
            {
                filedType: 'input',
                filedValType: 'text',
                filedName: '收货地址',
                filedRequired: true,
                filedPlaceholder: '请输入收货地址',
                filedDataName: 'address',
                isCheck:false,
            },
            {
                filedType: 'input',
                filedValType: 'text',
                filedName: '年级',
                filedRequired: true,
                filedPlaceholder: '请输入年级',
                filedDataName: 'grade',
                isCheck:false,

            },
            {
                filedType: 'input',
                filedValType: 'text',
                filedName: '机构名称',
                filedRequired: false,
                filedPlaceholder: '请输入机构名称',
                filedDataName: 'company',
                isCheck:false,
            },
            {
                filedType: 'input',
                filedValType: 'text',
                filedName: '职位名称',
                filedRequired: false,
                filedPlaceholder: '请输入职位名称',
                filedDataName: 'job',
                isCheck:false,
            },
            {
                filedType: 'input',
                filedValType: 'select',
                filedName: '所在大区',
                filedRequired: false,
                filedPlaceholder: '浦东新区|黄浦区|徐汇区|长宁区|静安区|普陀区|闸北区|虹口区|嘉定区|杨浦区|闵行区|宝山区|金山区|松江区|青浦区|南汇区|奉贤区',
                filedDataName: 'region',
                filedDataSelect:['浦东新区','黄浦区','徐汇区','长宁区','静安区','普陀区','闸北区','虹口区','嘉定区','杨浦区','闵行区','宝山区','金山区','松江区','青浦区','南汇区','奉贤区'],
                isCheck:false,
            },
            {
                filedType: 'textarea',
                filedValType: 'textarea',
                filedName: '备注',
                filedRequired: true,
                filedPlaceholder: '请输入备注',
                filedDataName: 'remark',
                isCheck:false,
            },
            {
                filedType: 'input',
                filedValType: 'text',
                filedName: '车牌登记',
                filedRequired: false,
                filedPlaceholder: '请输入车牌号',
                filedDataName: 'license_plate',
                isCheck:false,
            },
            {
                filedType: 'input',
                filedValType: 'text',
                filedName: '校区数量',
                filedRequired: false,
                filedPlaceholder: '请输入校区数量',
                filedDataName: 'studios_num',
                isCheck:false,
            },
            {
                filedType: 'input',
                filedValType: 'text',
                filedName: '营收规模',
                filedRequired: false,
                filedPlaceholder: '请输入营收规模(万元)',
                filedDataName: 'revenue_scale',
                isCheck:false,
            },
            {
                filedType: 'input',
                filedValType: 'text',
                filedName: '推荐人',
                filedRequired: false,
                filedPlaceholder: '请输入推荐人',
                filedDataName: 'recommender',
                isCheck:false,
            },
            {
                filedType: 'input',
                filedValType: 'text',
                filedName: '城市',
                filedRequired: false,
                filedPlaceholder: '请输入城市',
                filedDataName: 'city',
                isCheck:false,
            },
]

import moment from 'moment'
import url from '@/utils/URL'
import C_ITEMS from '@/utils/items'
import ImageModal from '@/views/modal/images'
import chinaOptions from '@/utils/chinaDivision'

export default {
  name: 'EditModal',
  inject: ['parent'],
  props: {
    item: Object,
    isEdit:Number,
  },
  components: {
    ImageModal,
  },
  provide() {
    return {
      parent: this
    }
  },

  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 18 },
      ModalTitle: '新增活动',
      visible: false,
      loading: false,
      tags:[],
      confirmLoading: false,
      formData,
      form: this.$form.createForm(this),
      seleteItems: {
        qywx_ids:[],
        booked:C_ITEMS.isPrivate
      },
      columns,
      list:[],
      imageUrl: '',
      imageUrls: [],
      imageRoot: url.imageRoot,
      uploadParams: {},
      imagesVisible: false,
      imageKey: '',

      meeting_description: '',
      editor: {},
      mapValue: {},
      mapData: {},
      chinaDivision:chinaOptions,
      chinaDivisionValue:[],
      chinaDivisionValueL:[],
      defaultAddDomSettingData,
      defaultList:[
        {
            filedType: 'input',
            filedValType: 'text',
            filedName: '学员姓名',
            filedRequired: true,
            filedPlaceholder: '请输入学员姓名',
            filedDataName: 'name'
        },
        {
            filedType: 'input',
            filedValType: 'text',
            filedName: '手机号',
            filedRequired: true,
            filedPlaceholder: '请输入手机号',
            filedDataName: 'cellphone'
        },
      ],
      defaultListF:[],
      isEditTag:false,
      showMap:false
    }
  },


  async created() {
    this.visible = true
    await this.$nextTick()
    this.getTag()
    if (this.isEdit !=0 ) {
      if(this.isEdit ===1 ){
        this.ModalTitle = '编辑活动'
      }else{
        this.ModalTitle = '复制活动'
      }
      let item = await this.getDetail(this.item.meeting_id)
      let formFields = {
        meeting_name:item.meeting_name,
        meeting_hotline:item.meeting_hotline,
        // province:this.chinaDivisionValue,
        open_time:moment(item.open_time),
        close_time:moment(item.close_time),
        show_booked:item.show_booked,
        address:item['address'],
        meeting_qywx_id:item['meeting_qywx_id'],
        meeting_cover:item['meeting_cover'],
        meeting_images:item['meeting_images'],
        tags:item['tags']
      }
      this.list = item.meeting_ticket
      this.imageUrl = item['meeting_cover']
      this.imageUrls = item['meeting_images']
      this.meeting_description = item['meeting_description']
      // this.meeting_qywx_id = item['meeting_qywx_id']
      this.defaultList = JSON.parse(item['meeting_form_edit'])
      this.mapValue = {
        address: item['address'],
        lng: item['lng'],
        lat: item['lat'],
      }
      this.showMap = true
      await this.$nextTick()
      this.form.setFieldsValue(formFields)
    }else{
      let obj = {
        name:null,
        max_count:null,
        price:null,
        need_vip:0,
        can_refund:0,
        can_repeat_purchase:1,
        id:'0'
      }
      this.list.push(obj)
      this.form.setFieldsValue({show_booked:1})
    }
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    async getTag(){
        let res = await this.$store.dispatch('searchGetTagsAction', {})
        this.tags = res.data
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async getStaff(val){
        this.loading = true
        let obj = {q:val}
        let res = await this.$store.dispatch('searchQyweEmployeeAction', {params:obj})
        this.seleteItems.qywx_ids = res.data
        this.loading = false
    },
    handleEmployee(e){
        this.getStaff(e.target.value)
    },
    onSearchEmployee(e) {
        this.getStaff(e)
    },
    async getDetail(meeting_id) {
      this.loading = true
      let res = await this.$store.dispatch('meetingCampaignDetailAction', {data: {meeting_id}})
      let chinaDivisionValue = []
      let chinaDivisionValuel = []
      this.chinaDivision.forEach(item=>{
        if(item.label === res.data.province){
          chinaDivisionValue.push(item.value)
          chinaDivisionValuel.push({label:item.label})
          item.children.forEach(it=>{
            if(it.label === res.data.city){
              chinaDivisionValue.push(it.value)
              chinaDivisionValuel.push({label:it.label})
              it.children.forEach(i=>{
                if(i.label === res.data.district){
                    chinaDivisionValue.push(i.value)
                    chinaDivisionValuel.push({label:i.label})
                }
              })
            }
          })
        }
      })
      if(this.isEdit === 2){
        res.data.meeting_ticket.forEach(item=>{
          delete item.type_status
        })
      }
      let defaultList = JSON.parse(res.data.meeting_form_edit)
      defaultList.forEach(item=>{
        this.defaultAddDomSettingData.forEach(it=>{
          if(item.filedName == it.filedName){
            it.isCheck = true
          }
        })
      })
      this.chinaDivisionValue = chinaDivisionValue
      this.chinaDivisionValueL = chinaDivisionValuel
      this.getStaff()
      this.loading = false
      return res.data
    },
    changeEditor(key, value) {
      this.editor[key] = value
      this.form.setFieldsValue({meeting_description:value})
    },
    changeMap(data) {
      this.mapData = data
      this.form.setFieldsValue({address:this.mapData.name})
    },
    async handleOk() {
      try {
        const { item, form , isEdit} = this
        let params = await form.validateFields()
        let list = JSON.parse(JSON.stringify(this.list))
        if(isEdit === 1){
          for (const item of list) {
            if(!item.name){
              this.$message.warning('请填写票种名称!!')
              return false
            }
            if(!item.max_count){
              item.max_count = 0
            }
            if(!item.price){
              item.price = 0
            }
            if(item.id){
              if(item.id<=0){
                delete item.id
              }
            }
          }
        }else{
          for (const item of list) {
            if(!item.name){
              this.$message.warning('请填写票种名称!!')
              return false
            }
            if(!item.max_count){
              item.max_count = 0
            }
            if(!item.price){
              item.price = 0
            }
            if(item.id){
              delete item.id
            }
          }
        }
        this.confirmLoading=true
        if (params) {
          if (isEdit === 1) {
            params.meeting_id = item.meeting_id
          }
          params.open_time = moment(params.open_time).format('YYYY-MM-DD HH:mm')
          params.close_time = moment(params.close_time).format('YYYY-MM-DD HH:mm')
          params.meeting_form_edit = JSON.stringify(this.defaultList)
          params.meeting_ticket = list
          params.meeting_cover = this.imageUrl
          params.meeting_images = this.imageUrls 
          params.meeting_description = this.editor.meeting_description
          // params.province = this.chinaDivisionValueL[0].label
          // params.city = this.chinaDivisionValueL[1].label
          // params.district = this.chinaDivisionValueL[2].label
          params.lat = this.mapData.lat 
          params.lng = this.mapData.lng
          await this.$store.dispatch(isEdit === 1 ? 'meetingCampaignUpdateAction' : 'meetingCampaignAddAction', { data: params })
            .then(res=>{
              form.resetFields()
              this.parent.hideEditModal(1)
            })
            .catch(err=>{
              this.confirmLoading=false;
            })
        }
      } catch {
      }
    },
    handleCancel() {
      this.parent.hideEditModal(0)
    },
    closeImg(e) {
      e.stopPropagation()
      this.imageUrl = ''
      this.form.setFieldsValue({meeting_cover:this.imageUrl})
    },
    closeImg2(e, index) {
      e.stopPropagation()
      this.imageUrls.splice(index, 1)
      this.form.setFieldsValue({meeting_images:this.imageUrls})
    },

    showImagesModal(imageKey) { 
      this.imageKey = imageKey
      this.imagesVisible = true
    },
    onChangeOptions(a,b){
      this.chinaDivisionValueL = b
    },
    hideImagesModal(image) {
      if (image) {
        if (this.imageKey === 'meeting_images') {
          this.imageUrls.push(image.path)
          this.form.setFieldsValue({meeting_images:this.imageUrls})
        } else {
          this.imageUrl = image.path
          this.form.setFieldsValue({meeting_cover:this.imageUrl})
        }
      }
      this.imagesVisible = false
    },
    addTicket(){
      let obj = {
        name:null,
        max_count:null,
        price:null,
        need_vip:0,
        can_refund:0,
        can_repeat_purchase:1,
        id:`-${this.list.length}`
      }
      this.list.push(obj)
    },
    delTicket(index){
      if(this.list[index].type_status){
        this.list[index].type_status = 0
      }else{
        this.list.splice(index,1)
      }
      
    },
    handleChange(e,record,name,num){
      if(num === 0){
        record[name] = e.target.value
      }else if(num === 1){
        record[name] = e
      }else{
        if(e.target.checked){
          record[name] = 1
        }else{
          record[name] = 0
        }
      }
      
    },
    handleTagCheck(item){
      item.isCheck = true
      let defaultListF =[
        {
            filedType: 'input',
            filedValType: 'text',
            filedName: '学员姓名',
            filedRequired: true,
            filedPlaceholder: '请输入学员姓名',
            filedDataName: 'name'
        },
        {
            filedType: 'input',
            filedValType: 'text',
            filedName: '手机号',
            filedRequired: true,
            filedPlaceholder: '请输入手机号',
            filedDataName: 'cellphone'

        },
      ]
      this.defaultAddDomSettingData.forEach(item=>{
        if(item.isCheck){
          defaultListF.push(item)
        }
      })
      this.defaultListF = defaultListF
    },
    deleteTag(index){
      this.defaultListF.splice(index,1)
      let num = index - 2
      this.defaultAddDomSettingData[num].isCheck = false
    },
    handleTagEdit(){
      this.defaultListF = [...this.defaultList]
      this.isEditTag = true
    },
    handleTagSave(){
      this.defaultList = [...this.defaultListF]
      this.isEditTag = false
    },
    handleTagCancal(){
      this.defaultListF = []
      this.defaultAddDomSettingData.forEach(item=>{
        item.isCheck = false
      })
      this.isEditTag = false
    },
    onChangeCheckbox(e,item){
      item.filedRequired = !item.filedRequired
    }
  }
}
</script>
 
 <style lang="scss" scoped>
  .add-ticket{
    text-align:center;
    border:1px dashed #e5e6e7;
    cursor:pointer;
    margin-top:10px;
    line-height:30px
  }
  .hdgl-sign{
    &-item{
      display:flex;
      align-items:center;
      .ant-radio-group{
        width: 73.67%;
      }
    }
    &-edit{
      text-align: right;
      .ant-btn{
        margin-right: 5px;
      }
    }
    &-input{
      width:72%;
      height: 32px;
      line-height: 32px;
      margin-right:10px;
      padding: 0 11px;
      border:1px solid #d9d9d9;

    }
    &-warp{
      border:1px dashed #d9d9d9;
      padding:5px;
      margin-top: 10px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
    &-tag{
      width:90px;
      border:1px solid #d9d9d9;
      line-height: 32px;
      text-align: center;
      margin: 5px;
      border-radius: 2px;
      cursor: pointer;
    }
    &-tag-check{
      width:90px;
      border:1px solid #00cca2;
      line-height: 32px;
      text-align: center;
      margin: 5px;
      background: #00cca2;
      color: #fff;
      border-radius: 2px;
      cursor: pointer;
    }
  }
 </style>
