<template>
  <div class="zcxy">
    <div class="clearfix table-tools">
      <div class="buttons">
        <a-form layout='inline'>
          <a-form-item v-if="canSave">
            <a-button icon="plus" type="primary" @click="showEditModal()">新增活动</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="search">
        <a-form layout='inline' @submit="searchList">
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>活动名称</span>
              </template>
              <a-input allowClear v-model.trim="searchData.meeting_name" placeholder="活动名称" style="width: 160px"/>
            </a-tooltip>
          </a-form-item>

          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>添加时间</span>
              </template>
              <a-range-picker
              style="width: 240px"
              :ranges="rangesData"
              v-model='searchData.add'
              allowClear
              @change="(val,time)=>handleTime(val,time,'add')" />
            </a-tooltip>
          </a-form-item>

          <a-form-item>
              <a-select style="width: 200px" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' v-model='searchData.created_by' allowClear showSearch @search="handleSearch" placeholder="请选择添加人" :filterOption="filterOption">
              <a-select-option v-for="(d, index) of staffList" :key="index" :value="d['employee_id']">{{ d['filter_name'] }}</a-select-option>
              </a-select>
          </a-form-item>

          <a-form-item>
            <a-radio-group :value="searchData.status">
              <a-radio-button v-for="(d, index) of meetingStatus" :key="index" :value="d['value']" @click="handleTypeButChange(d,'status')">
                  {{ d['label'] }}
              </a-radio-button>
            </a-radio-group>
          </a-form-item>

          <a-form-item v-if="canIndex">
            <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-table size="middle" :pagination="false" :bordered='false' rowKey="meeting_id"
        :columns="columns" :dataSource="list" @change="handleChange">
          <template slot="index" slot-scope="text, record , index">
            <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
          </template>
          <template slot="date" slot-scope="text">
            <span v-html="text"></span>
          </template>

          <template slot="meeting_name" slot-scope="text,record">
            <a href="javascript:;"  @click="showListModal(record)">{{text}}</a>
          </template>

          <template slot="already_count" slot-scope="text,record">
            <a href="javascript:;"  @click="showListModal(record)">{{text}}</a>
          </template>

          <template slot="image" slot-scope="text">
            <LImg :src="text" style="width: 100px; height: 42px;"/>
          </template>

          <template slot="action" slot-scope="text,record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">操作<a-icon type="down" /></a>
              <a-menu slot="overlay">
                  <a-menu-item v-if="canUpdate" key="1" @click="showEditModal(record,1)">
                  活动编辑
                  </a-menu-item>
                  <a-menu-item v-if="canRead" key="2" @click="showListModal(record)">
                  活动详情
                  </a-menu-item>
                  <a-menu-item v-if="canSave" key="3" @click="showEditModal(record,2)">
                  复制活动
                  </a-menu-item>
                  <a-menu-item v-if="canSave" key="4" @click="downQRcode(record)">
                  二维码
                  </a-menu-item>
                  <a-menu-item v-if="canSave" key="6" @click="showPosterModal(record)">
                  定制海报
                  </a-menu-item>
                  <a-menu-item key="5">
                      <a href="javascript:;" @click="showCopyModal(record)">分享链接</a>
                  </a-menu-item>
              </a-menu>
            </a-dropdown>
          </template>

          <template slot="status" slot-scope="text,record">
              <a-switch checkedChildren="进行中" unCheckedChildren="已下线" :checked="record.status == '1'" @change="changeStatus(record)"/>
          </template>
      </a-table>
    </div>
    <div class="page">
      <a-pagination
        :pageSizeOptions="pageSizeOptions"
        :total="pageParams.totalCount"
        showSizeChanger
        :pageSize="pageParams.perPage"
        v-model="current"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
      >
          <template slot='buildOptionText' slot-scope='props'>
            <span v-if="props.value!=='100'">{{props.value}}条/页</span>
            <span v-if="props.value==='100'">100条/页</span>
          </template>
        </a-pagination>
    </div>
    <editModal v-if="editVisible" :isEdit='isEdit' :item="modalData"/>
    <listModal v-if="listVisible" :item="modalData"/>
    <qrCodeModal v-if="isQrCodeModal" :item="modalData"/>
    <posterModal v-if="isPosterModal" :item="modalData"/>
    <copyModal v-if="isCopyModal" :item="modalData"/>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index'},
  { title: '活动头图', dataIndex: 'meeting_cover', key: 'name',scopedSlots: { customRender: 'image' },},
  { title: '活动名称', dataIndex: 'meeting_name', key: '1' , scopedSlots: { customRender: 'meeting_name' }},
  { title: '联系电话', dataIndex: 'meeting_hotline', key: '2' },
  { title: '浏览数', dataIndex: 'view_count', key: '6' ,align:'center'},
  // { title: '招募数', dataIndex: 'ticket_count', key: '31' ,align:'right',sorter:()=>{}},
  { title: '报名数', dataIndex: 'already_count', key: '3' ,align:'center', scopedSlots: { customRender: 'already_count' },sorter:()=>{}},
  { title: '价格', dataIndex: 'meeting_price', key: '4' ,align:'right'},
  { title: '收入', dataIndex: 'meeting_recevie_total', key: '5' ,align:'right'},
  { title: '活动时间', dataIndex: 'meeting_date', key: '7' , scopedSlots: { customRender: 'date' },align:'center',sorter:()=>{}},
  { title: '添加人', dataIndex: 'created_by', key: '8' ,sorter:()=>{}},
  { title: '企业微信', dataIndex: 'meeting_qywx_id', key: 'meeting_qywx_id'},
  { title: '添加时间', dataIndex: 'created_at', key: '9' ,sorter:()=>{}},
  { title: '活动状态', dataIndex: 'status', key: '10' , scopedSlots: { customRender: 'status' }},
  { title: '操作', key: 'operation', fixed: 'right', scopedSlots: { customRender: 'action' }},
]

import moment from 'moment'
import C_ITEMS from '@/utils/items'
import editModal from './editModal'
import listModal from './listModal'
import qrCodeModal from './qrCodeModalF'
import posterModal from './posterModal'
import ranges from "@/common/mixins/ranges"
import authority from '@/common/mixins/authority'
import tableMixins from '@/common/mixins/table'
import copyModal from '@/views/market/weipages/zxbb/copyModal'
export default {
  name: 'gjjl',
  provide() {
    return {
      parent: this
    }
  },

  components: {
    editModal,
    listModal,
    copyModal,
    qrCodeModal,
    posterModal
  },

  data() {
    return {
      loading: false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
      staffList: [],
      current: 1,
      isEdit:0,
      meetingStatus: C_ITEMS.meetingStatus,
      meetingShow: C_ITEMS.meetingShow,
      searchData: {
        status:undefined
      },

      visible: false,
      modalData: {},
      editVisible: false,
      listVisible: false,
      isCopyModal: false,
      isQrCodeModal: false,
      isPosterModal: false,
      authType:['market','meeting'],
    }
  },
  mixins: [ authority, tableMixins, ranges ],
  created () {
      this.getStaff()
  },
  methods: {
    handleSearch(val){
        this.getStaff(val)
    },
    closeSearch() {
      this.visible = false
    },
    handleTypeButChange(e,name){
      if(this.searchData[name] == e.value){
        this.searchData[name] = undefined
      }else{
        this.searchData[name] = e.value
      }
      this.searchList()
    },
    async getStaff(val){
        let obj = {q:val}
        let res = await this.$store.dispatch('searchAccountEmployeeAction', {params:obj})
        this.staffList = res.data
    },
    async getList() {
      if(!this.canIndex){
          this.$message.warning('您暂无权限!!')
          return false
      }
      this.loading = true
      let obj = {}
      let { searchData } = this
      if (searchData) {
        for (let k in searchData) {
          if (k === 'add') {
            obj['start_date'] = searchData[k][0]?moment(searchData[k][0]).format('YYYY-MM-DD'):''
            obj['end_date'] = searchData[k][1]?moment(searchData[k][1]).format('YYYY-MM-DD'):''
          } else {
            obj[k] = searchData[k]
          }
        }
      }
      this.searchParams.search = obj
      let res = await this.$store.dispatch('meetingCampaignAction', { data: this.searchParams })
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },
    handleChange(pagination, filters, sorter){
        if(sorter.order){
            if(sorter.order === "ascend"){
                this.searchParams['sort'] = `${sorter.field}`
            }else{
                this.searchParams['sort'] = `-${sorter.field}`
            }
            }else{
            this.searchParams['sort'] = ''
            }
        this.getList()
    },
    showEditModal(item,num) {
      if(item){
        this.isEdit = num
      }else{
        this.isEdit = 0
      }
      this.modalData = item
      this.editVisible = true
    },
    hideEditModal(type) {
      if (type === 1) {
        this.getList()
      }
      this.editVisible = false
    },
    showListModal(item) {
      // this.modalData = item
      // this.listVisible = true
      this.$router.push({path:"/market/meeting/detail",query:{meeting_id:item.meeting_id}})
    },
    hideListModal(type) {
      if (type === 1) {
        this.getList()
      }
      this.listVisible = false
    },

    handleTime(val,time,name){
        this.searchData[name] = val
        this.$forceUpdate()
    },

    changeStatus(record) {
      if (record.status == '1') {
        this.deleteItem(record)
      } else {
        this.recoveryItem(record)
      }
    },
    deleteItem(item) { 
      let that = this
      this.$confirm({
        title: `您确定要删除活动"${item.meeting_name}"吗？`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('meetingCampaignDeleteAction', { data: { meeting_id: item.meeting_id } })
            if (res) {
              that.getList()
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    },
    recoveryItem(item) {
      let that = this
      this.$confirm({
        title: `您确定要恢复活动"${item.meeting_name}"吗？`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('meetingCampaignRecoveryAction', { data: { meeting_id: item.meeting_id } })
            if (res) {
              that.getList()
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    },
    showCopyModal(item){
      this.modalData = item
      this.isCopyModal = true
    },
    hideCopyModal(){
      this.isCopyModal = false
    },
    downQRcode(item){
      this.modalData = item
      this.isQrCodeModal = true
    },
    hideQrCodeModal(){
      this.isQrCodeModal = false
    },
    showPosterModal(item){
      this.modalData = item
      this.isPosterModal = true
    },
    hidePosterModal(){
      this.isPosterModal = false
    }
  }
}
</script>
