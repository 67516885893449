<template>
  <div class="zcxy">
    <div class="clearfix table-tools">
      <div class="buttons">
        <a-form layout='inline'>
          <a-form-item v-if="canSave">
            <a-button icon="plus" type="primary" @click="showEditModal()">添加代理商渠道</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="search">
        <a-form layout='inline' @submit="searchList">
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>渠道名称</span>
              </template>
              <a-input allowClear v-model.trim="searchData.title" placeholder="渠道名称" style="width: 160px"/>
            </a-tooltip>
          </a-form-item>
          <a-form-item v-if="canIndex">
            <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-table size="middle" :pagination="false" :bordered='false' rowKey="channel_id"
        :columns="columns" :dataSource="list">
        <template slot="index" slot-scope="text, record , index">
          <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
        </template>
          <template slot="action" slot-scope="text,record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">操作<a-icon type="down" /></a>
              <a-menu slot="overlay">
                  <a-menu-item v-if="canUpdate" key="1" @click="showEditModal(record)">
                  编辑
                  </a-menu-item>
                  <a-menu-item key="2">
                    <a href="javascript:;" v-clipboard:copy="record.access_url" v-clipboard:success="onCopy" v-clipboard:error="onError">复制链接</a>
                  </a-menu-item>
                  <a-menu-item v-if="canRead" key="3">
                  下载订单记录
                  </a-menu-item>
              </a-menu>
            </a-dropdown>
          </template>
      </a-table>
    </div>
    <div class="page">
      <a-pagination
        :pageSizeOptions="pageSizeOptions"
        :total="pageParams.totalCount"
        showSizeChanger
        :pageSize="pageParams.perPage"
        v-model="current"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
      >
          <template slot='buildOptionText' slot-scope='props'>
            <span v-if="props.value!=='100'">{{props.value}}条/页</span>
            <span v-if="props.value==='100'">100条/页</span>
          </template>
        </a-pagination>
    </div>
    <editModal v-if="editVisible" :isEdit="isEdit" :item="modalData"/>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index' },
  { title: '渠道名称', width: '100px', dataIndex: 'channel_name', key: 'name' },
  { title: '活动名称', dataIndex: 'meeting_name', key: '1' },
  { title: '渠道代码', dataIndex: 'code', key: '2' },
  { title: '成单数统计', dataIndex: 'channel_count', key: '3' },
  { title: '展示数统计', dataIndex: 'channel_view_count', key: '4' },
  { title: '成交金额', dataIndex: 'channel_total_fee', key: '5' },
  { title: '渠道状态', dataIndex: 'channel_status', key: '7' },
  { title: '添加人', dataIndex: 'created_by', key: '8' },
  { title: '添加时间', dataIndex: 'created_at', key: '6' },
  { title: '操作', key: 'operation', align: 'right', scopedSlots: { customRender: 'action' }},
]

import C_ITEMS from '@/utils/items'
import editModal from './editModal'
import authority from '@/common/mixins/authority'
import tableMixins from '@/common/mixins/table'
export default {
  name: 'gjjl',
  provide() {
    return {
      parent: this
    }
  },

  components: {
    editModal,
  },

  data() {
    return {
      loading: false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
      current: 1,

      searchData: {},
      isEdit:0,
      visible: false,
      modalData: {},
      editVisible: false,
      authType:['market','meeting'],
    }
  },
  mixins: [ authority,tableMixins ],

  methods: {
    closeSearch() {
      this.visible = false
    },
    async getList() {
      if(!this.canIndex){
          this.$message.warning('您暂无权限!!')
          return false
      }
      this.loading = true
      let obj = {}
      let { searchData } = this
      if (searchData) {
        for (let k in searchData) {
          obj[k] = searchData[k]
        }
      }
      this.searchParams.search = obj
      let res = await this.$store.dispatch('meetingChannelAction', { data: this.searchParams })
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },
    showEditModal(item) {
      this.isEdit = item?1:0
      this.modalData = item
      this.editVisible = true
    },
    hideEditModal(type) {
      if (type === 1) {
        this.getList()
      }
      this.editVisible = false
    },
    onCopy(){
        this.$message.success('已复制到剪切板!')
    },
    onError(){
        this.$message.error('操作失败!')
    }
  }
}
</script>
